'use client';

import { ApolloProvider } from '@apollo/client';
import { Background } from '@drugfreesleep/components/background/Background';
import { Button } from '@drugfreesleep/components/button/Button';
import { HeroOneButton } from '@drugfreesleep/components/hero/HeroOneButton';
import { IntakeForm } from '@drugfreesleep/components/intakeForm/intakeForm';
import { Section } from '@drugfreesleep/components/layout/Section';
import { getApolloClient } from '@drugfreesleep/modules/graphql/apollo';
import Image from 'next/image';
import img from 'public/assets/images/SEO_creative-idea,-idea,-light-bulb.png';
import img2 from 'public/assets/images/SEO_website-development,-website-optimization,-gear,-website.png';

import { Meta } from '../components/layout/Meta';
import { AppConfig } from '../config/AppConfig';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

const Base = () => {
  const client = getApolloClient();

  const checkmark = () => {
    return (
      <div className="px-3 pt-2">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6"
        >
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  };

  const heroDesc = () => {
    return (
      <div className="py-4">
        <ul style={{ listStyleType: 'none' }}>
          <li className="flex">
            {checkmark()}For your existing product, or new venture.
          </li>
          <li className="flex">
            {checkmark()}A veteran team tailored to your needs.
          </li>
          <li className="flex">
            {checkmark()}No long term contracts - Pay as you go.
          </li>
        </ul>
      </div>
    );
  };
  return (
    // <AccessCodeEnforcer>
    <ApolloProvider client={client}>
      <div className="text-gray-600 antialiased">
        <Meta title={AppConfig.title} description={AppConfig.description} />
        <Navbar />
        <div id="vision" style={{ paddingTop: '200pt', marginTop: '-200pt' }}>
          <Background color="bg-primary-100">
            <Section yPadding="py-20">
              <HeroOneButton
                title={'We Build Your Software Vision'}
                titleClassName="text-black"
                description={heroDesc()}
                button={
                  <div className="w-100 flex justify-around">
                    <a href="#getstarted">
                      <Button>Get Started</Button>
                    </a>
                  </div>
                }
                image={{
                  src: '/assets/images/webDevLanding.png',
                  alt: 'Hero screenshot',
                }}
              />
            </Section>
          </Background>
        </div>
        <Background color="bg-white">
          <Section yPadding="py-6 md:py-24">
            <div className="flex w-full flex-col justify-center md:flex-row">
              <div
                className="flex w-full flex-col justify-center md:w-1/2"
                id="about"
                style={{ paddingTop: '50pt', marginTop: '-50pt' }}
              >
                <div className="order-2 text-center text-2xl font-bold text-black md:order-1 md:text-left md:text-4xl">
                  Bring your product vision to life.
                  <div className="mt-2 text-sm font-normal md:text-lg">
                    Whether it is the latest in AI integration, or a simple
                    website renovation, our expert team is ready and waiting.
                  </div>
                </div>
                <div className="order-1 flex justify-center md:order-2">
                  <div className="mt-5">
                    <Image
                      src={img}
                      alt=""
                      width={80}
                      height={80}
                      className="md:width[120px] md:height[120px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col justify-center md:w-1/2">
                <div className="flex justify-center">
                  <div className="mb-5">
                    <Image
                      src={img2}
                      alt=""
                      width={80}
                      height={80}
                      className="md:width[120px] md:height[120px]"
                    />
                  </div>
                </div>
                <div className="text-center text-2xl font-bold text-black md:text-left md:text-3xl">
                  A complete software team for your needs.
                  <div className="mt-2 text-sm font-normal md:text-lg">
                    A quick and effective way to acquire a complete and
                    high-performance product development team.
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </Background>
        <Background color="bg-primary-100">
          <Section yPadding="py-24">
            <div
              className="flex flex-col items-center justify-center text-center"
              id="howitworks"
              style={{ paddingTop: '200pt', marginTop: '-200pt' }}
            >
              <div className="pb-4 text-4xl text-black">
                Your Software Project Built Efficiently
              </div>
              <div className="justify-left flex w-2/3 flex-row text-left md:w-1/2 lg:w-1/3 xl:w-1/3">
                <div>
                  <div className="py m-4 border-spacing-2 border border-solid border-black px-4 text-3xl font-bold text-black">
                    1
                  </div>
                </div>
                <div className="my-4">
                  <div className="text-2xl font-bold text-black">
                    Schedule an introductory call
                  </div>
                  <div className=" text-xl">
                    Talk with our technical project expert.
                  </div>
                </div>
              </div>
              <div className="justify-left flex w-2/3 flex-row text-left md:w-1/2 lg:w-1/3 xl:w-1/3">
                <div>
                  <div className="py m-4 border-spacing-2 border border-solid border-black px-4 text-3xl font-bold text-black">
                    2
                  </div>
                </div>
                <div className="my-4">
                  <div className="text-2xl font-bold text-black">
                    Get a plan, detailed cost estimate, and estimated schedule
                  </div>
                  <div className=" text-xl">
                    Decide what works for your product vision.
                  </div>
                </div>
              </div>
              <div className="justify-left flex w-2/3 flex-row text-left md:w-1/2 lg:w-1/3 xl:w-1/3">
                <div>
                  <div className="py m-4 border-spacing-2 border border-solid border-black px-4 text-3xl font-bold text-black">
                    3
                  </div>
                </div>
                <div className="my-4">
                  <div className="text-2xl font-bold text-black">
                    View immediate progress at bi-weekly project Scrum meetings
                  </div>
                  <div className=" text-xl">
                    Always know the status of your software.
                  </div>
                </div>
              </div>
              <div className="justify-left flex w-2/3 flex-row text-left md:w-1/2 lg:w-1/3 xl:w-1/3">
                <div>
                  <div className="py m-4 border-spacing-2 border border-solid border-black px-4 text-3xl font-bold text-black">
                    4
                  </div>
                </div>
                <div className="my-4">
                  <div className="text-2xl font-bold text-black">
                    Pay month-to-month with no long-term contracts
                  </div>
                  <div className=" text-xl">
                    Flexibility to meet your budget.
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </Background>
        <Background color="bg-white">
          <Section yPadding="py-24">
            <div
              className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
              id="services"
              style={{ paddingTop: '200pt', marginTop: '-200pt' }}
            >
              <div className="">
                <div className="pb-3 text-4xl text-black">Web Development</div>
                <div className="">
                  {`Fluent in modern web technologies, we build web applications
                with an eye for detail, performance, security, and
                responsiveness.`}
                </div>
              </div>
              <div className="">
                <div className="pb-3 text-4xl text-black">
                  Mobile Applications
                </div>
                <div className="">
                  {`Whether your app is just an idea or years in development, we
                build intuitive, pixel-perfect mobile applications.`}
                </div>
              </div>
              <div className="">
                <div className="pb-3 text-4xl text-black">
                  Back End Development
                </div>
                <div className="">
                  {`The engine that drives your application is the backend. We'll
                architect your applications in a configurable, extensible way.
                Regulated? No problem. We're accustomed to answering auditors.`}
                </div>
              </div>
              <div className="">
                <div className="pb-3 text-4xl text-black">Infrastructure</div>
                <div className="">
                  {`As the old adage goes, "the strongest part of the house is the
                foundation." Our experts leverage Infrastructure as code (IaC)
                to ensure your applications run on optimal resources, achieving
                scalability, security, and cost-effectiveness.`}
                </div>
              </div>
              <div className="">
                <div className="pb-3 text-4xl text-black">Product</div>
                <div className="">
                  {`A great product team is instrumental to a high-functioning
                  engineering team. They are the conduit to the business. We can
                  help with user research, agile process, story-writing, and
                  feature ideation.`}
                </div>
              </div>
              <div className="">
                <div className="pb-3 text-4xl text-black">Design</div>
                <div className="">
                  {`Customers buy with their eyes. Tap in to our deep roster of
                world-class designers to get a look-and-feel that delights.`}
                </div>
              </div>
            </div>
          </Section>
        </Background>
        <Background color="bg-primary-100">
          <Section yPadding="pt-20 pb-10">
            <div className="px-24">
              <div className="mb-4 text-4xl font-bold text-gray-900">
                Get Started
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                <IntakeForm />
                <div className="mt-5 flex-col text-black">
                  <div className="mb-3 flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mx-2 h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                      />
                    </svg>
                    <div>
                      1624 Garden St, Suite 1 <br />
                      Santa Barbara, CA, 93101
                    </div>
                  </div>
                  <div className="mb-3 flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mx-2 h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>

                    <div>(619)964-6891</div>
                  </div>
                  <div className="mb-3 flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mx-2 h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>

                    <div>Info@FronteraLabs.io</div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </Background>

        {/* <ManagedComponent slug="homepage" /> */}
        {/* <Hero /> */}
        {/* <Stats /> */}
        {/* <Features {...exampleFeaturesProps} /> */}
        {/* <VerticalFeatures /> */}
        {/* <Testimonial /> */}
        {/* <Pricing /> */}
        {/* <FAQ /> */}
        {/* <Banner /> */}
        <Footer />
      </div>
    </ApolloProvider>
    // </AccessCodeEnforcer>
  );
};

export { Base };
