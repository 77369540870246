import { useCreateEventMutation } from '@drugfreesleep/modules/graphql/generated-types';
import { useEffect, useState } from 'react';

const IntakeForm = () => {
  const [nameField, setNameField] = useState('');
  const [emailField, setEmailField] = useState('');
  const [messageField, setMessageField] = useState('');
  const [createEvent, { data, loading, error }] = useCreateEventMutation();

  useEffect(() => {
    console.log('data: ', data, 'loading: ', loading, 'error: ', error);
  }, [error]);

  const submitForm = () => {
    createEvent({
      variables: {
        input: {
          event: {
            userId: 14,
            programId: 2,
            interactionId: 31,
            type: 'program',
            subtype: 'contents',
            name: 'GetStarted',
            payload: {
              name: nameField,
              email: emailField,
              message: messageField,
            },
          },
        },
      },
    });
  };

  return (
    <form
      id="getstarted"
      className="flex flex-col "
      onSubmit={(event) => {
        event.stopPropagation();
        event.preventDefault();
        submitForm();
      }}
    >
      <input
        className="my-2"
        placeholder="Name"
        type="text"
        onChange={(e) => {
          setNameField(e.target.value);
        }}
      />
      <input
        className="my-2"
        placeholder="Email"
        type="text"
        onChange={(e) => {
          setEmailField(e.target.value);
        }}
      />
      <textarea
        className="my-2"
        placeholder="Message"
        onChange={(e) => {
          setMessageField(e.target.value);
        }}
      />
      <button
        className="focus:shadow-outline w-24 rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
        type="button"
        onClick={() => {
          submitForm();
        }}
      >
        Submit
      </button>
    </form>
  );
};

export { IntakeForm };
