import type { ReactNode } from 'react';

import { Logo } from '../../template/Logo';

type IFooterTwoRowsCopyrightProps = {
  siteName: string;
  children: ReactNode;
};

const FooterTwoRowsCopyright = (props: IFooterTwoRowsCopyrightProps) => (
  <>
    {props.children}

    <div className="mt-3 flex flex-col items-center justify-between gap-4 border-t border-gray-500 pt-10 text-center text-zinc-800 sm:flex-row sm:text-left">
      <Logo dark={true} />

      <div className="text-sm">
        {`© Copyright ${new Date().getFullYear()} ${
          props.siteName
        }. All Rights Reserved.`}
      </div>
    </div>
  </>
);

export { FooterTwoRowsCopyright };
