import { Background } from '../components/background/Background';
import { Button } from '../components/button/Button';
import { Section } from '../components/layout/Section';
import { NavbarMenuCenter } from '../components/navigation/NavbarMenuCenter';
import { Logo } from './Logo';

const Navbar = () => (
  <Background color="bg-white shadow sticky top-0">
    <Section yPadding="py-6">
      <NavbarMenuCenter
        logo={<Logo xl />}
        rightMenu={
          <>
            {/* <li className="hidden">
              <Link href="/">Login</Link>
            </li> */}
            <li>
              <a href="#vision">Vision</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#howitworks">How It Works</a>
            </li>
            <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#getstarted">
                <Button>Get Started</Button>
              </a>
            </li>
            {/* <li>
              <Link href="/course">
                <Button>Get Started</Button>
              </Link>
            </li> */}
          </>
        }
      />
      {/* <li>
					<Link href="/about">About</Link>
				</li> */}
      {/* <li>
          <Link href="/">Treatments</Link>
        </li> */}
    </Section>
  </Background>
);

export { Navbar };
